.box__text {
  width: 100%;
  height: 100%;
  padding: 1rem;
  overflow-wrap: break-word;
  box-sizing: border-box;
}

.box__text:focus-visible {
  outline: none;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.box {
  position: absolute;
  opacity: 1;
  display: flex;
  justify-content: center;
  max-width: 8rem;
  height: auto;
  user-select: none;
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 0.25rem;
  animation: fadeIn 0.2s ease-in-out;
}

.box::after {
  content: "";
  opacity: 0;
  position: absolute;
  top: -2.5rem;
  left: -14%;
  width: 128%;
  height: calc(100% + 3.5rem);
  background: rgb(0 0 0 / 6%);
  border-radius: 0.5rem;
  transition: opacity 0.2s ease-in-out;
  -webkit-backface-visibility: hidden;
  -webkit-perspective: 1000;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transform: translate3d(0, 0, 0);
  transform: translateZ(0);
  backdrop-filter: blur(1.8px);
  -webkit-backdrop-filter: blur(1.8px);
}

.box.--is-selected {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px;
}

.box.--is-selected:after,
.box.--is-hovered:after {
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

.box-tools {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  bottom: calc(var(--icon-size) * -1 - var(--selected-border-width) - 0.5rem);
  right: calc(var(--selected-border-width) * -1 + 0.15rem);
  z-index: 1;
  display: flex;
  height: var(--icon-size);
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: black;
  background-color: white;
  border: var(--selected-border);
  transition: opacity 0.2s ease-in-out;
}

.box-tools__tool {
  position: relative;
  width: var(--icon-size);
  height: var(--icon-size);
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-tools__tool:hover {
  cursor: pointer;
  background-color: var(--selected-color);
}

.box-tools__bgcolor {
  display: flex;
  justify-content: center;
  align-items: center;
}

.box-tools__bgcolor input,
.box-tools__color input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.box-tools__bgcolor-watch {
  width: 1rem;
  height: 1rem;
  border-radius: 0.05rem;
}

.box-tools__edit.--is-editing svg,
.box-tools__tool:hover svg {
  fill: white;
}

.box-tools__edit.--is-editing,
.box-tools__tool:hover {
  background-color: var(--selected-tool);
}

.box.--is-selected .box-tools {
  opacity: 1;
  pointer-events: auto;
}

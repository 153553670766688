:root {
  --selected-tool: black;
  --selected-color: #e2e9f0;
  --selected-border-width: 2px;
  --selected-border: var(--selected-border-width) solid var(--selected-color);
  --icon-size: 1.5rem;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html,
#root {
  background-color: #282c34;
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.canva {
  width: 1200px;
  height: 675px;
  background-color: aliceblue;
}

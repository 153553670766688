.nav {
  position: fixed;
  bottom: 1rem;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
}

.nav ul {
  list-style: none;
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: black;
  background-color: white;
  border: var(--selected-border);
}

.toolbar__tool {
  position: relative;
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
}

.toolbar__tool.--is-disabled {
  opacity: 0.2;
}

.toolbar__tool .toolbar__bgcolor-watch {
  width: 1rem;
  height: 1rem;
  border-radius: 0.05rem;
}

.toolbar__tool:not(.--is-disabled):hover {
  background-color: black;
}

.toolbar__tool:not(.--is-disabled):hover svg {
  fill: white;
}

.toolbar__tool input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.toolbar__tool svg {
  width: 1.5rem;
  height: 1.5rem;
}

.counter {
  max-width: 9rem;
  display: flex;
  flex-direction: row-reverse;
  flex-flow: row-reverse;
  flex-wrap: wrap;
  gap: 0.3rem;
  transform: scaleY(-1);
}

.counter__value {
  font-size: 0.9rem;
}

.counter-wrapper {
  display: flex;
  gap: 0.3rem;
  align-items: flex-end;
  line-height: 1;
}

.counter .counter__item {
  width: 1rem;
  height: 1rem;
  background-color: black;
  cursor: pointer;
}

.counter__item.--is-selected {
  border: 1px solid black;
}

.box-selection-tool {
  position: absolute;
  opacity: 0;
  z-index: 1000;
  background-color: transparent;
  border: 2px dashed rgba(0, 0, 0, 0.5);
  transition: opacity 0.2s ease-in-out;
  border-radius: 4px;
}

.box-selection-tool.--is-visible {
  opacity: 1;
}
